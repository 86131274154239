import * as Ja from './ja/auth';
import {getTextByLang} from "@/bootstraps/locale";

export const help = {
  loginMfa: getTextByLang({
    ja: Ja.loginMfa,
    // TODO: LANG
  }),
}

