









import { Vue, Component, Prop} from 'vue-property-decorator';

import {ValidatorRules} from '@/entities/user-entity';

import {SignupRequest} from "@/repositories/auth-repository";
import {ValidateWithScroll} from "@/utils/validation-handler";

@Component
export default class extends Vue {
}
