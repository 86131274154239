




























import { Vue, Component, Prop} from 'vue-property-decorator';

import {ValidatorRules} from '@/entities/user-entity';

import {SignupRequest} from "@/repositories/auth-repository";
import {ValidateWithScroll} from "@/utils/validation-handler";
import {i18n} from "@/bootstraps/locale";

@Component
export default class extends Vue {
  @Prop({required: false}) private email?: string;
  @Prop({default: i18n.t('次へ')}) private submitLabel!: string;

  private request: SignupRequest = {
    password: '',
    user: {
      name: '',
      company: {name: '', telno: ''}
    }
  }

  private get rules() {
    return {
      password: ValidatorRules.password,
      user: {
        name: ValidatorRules.name,
        company: {
          name: ValidatorRules.company.name,
          telno: ValidatorRules.company.telno
        }
      },
    };
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    this.$emit('submit', this.request);
  }
}
