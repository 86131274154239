






























import { Vue, Component, Prop} from 'vue-property-decorator';

import {ValidatorRules} from '@/entities/user-entity';

import {ValidateWithScroll} from "@/utils/validation-handler";
import { help } from '@/lang/help/auth';
import {createMaxStringRule, createMinStringRule, requiredRule} from "@/utils/validation-rules";
import AuthRepository from "@/repositories/auth-repository";
import LoadingHandler from "@/utils/loading-handler";

@Component
export default class extends Vue {
  @Prop({required: false}) private email?:string;

  private readonly help = help;

  private showMfa: boolean = false;

  private formData = {
    email: "",
    password: "",
    mfaCode: null as string | null,
  };

  created() {
    this.formData = {
      email: this.email || "",
      password: "",
      mfaCode: null,
    };
  }

  private get rules(){
    return {
      email: ValidatorRules.email,
      password: ValidatorRules.password,
      mfaCode: [ requiredRule, createMinStringRule(6), createMaxStringRule(6) ] ,
    };
  }

  private async login() {
    if (!(await ValidateWithScroll(this.$refs.loginForm as any))) return;

    return LoadingHandler(async () => {
      const data = this.formData;
      if (this.showMfa) {
        const user = await (new AuthRepository).loginWithMFACode(data.email, data.password, data.mfaCode!);
        this.$emit('login', user);
      } else {
        const user = await (new AuthRepository).login(data.email, data.password);
        if (user) {
          this.$emit('login', user);
        } else {
          this.showMfa = true;
        }
      }
    });
  }

  private async resendMfa() {

    return LoadingHandler(async () => {
      this.showMfa = false;

      await this.$nextTick();
      if (!(await ValidateWithScroll(this.$refs.loginForm as any))) return;

      const data = this.formData;
      await (new AuthRepository).regenerateMfaCode(data.email, data.password);
      this.showMfa = true;

      this.$message({ type: 'success', message: this.$t('認証コードを再作成し、再度送信しました'), showClose: true });
    });
  }
}
